@import url('https://fonts.googleapis.com/css?family=Gochi+Hand');
@import url('https://fonts.googleapis.com/css?family=Anonymous+Pro');
@import url('https://fonts.googleapis.com/css?family=Lato');

body {
    background-color: rgb(53, 43, 146);
    overflow-x: hidden;
    /* scrollbar-gutter: stable both-edges; */
}

.section {
    scrollbar-gutter: stable;
}

.nav_li {
    list-style: none;
    
}

.nav_elements {
    display: inline;
    margin: 10px 3vw;
    padding: 5px 15px;
    
}

.nav_bar {
    position: fixed;
    display: grid;
    place-items: center;
    background-color: black;
    width: 100vw;
    margin: -10px -10px;
    z-index: 10;
}
 

.nav_link {
    text-decoration: none;
    color: rgb(255, 255, 255);

}

.nav_link, .highlight {
    position: relative;
}

.nav_link:before, .highlight:before {
    content: "";
    position: absolute;
    bottom: -6px;
    height: 1px;
    width: 100%;
    transform: scale(0);
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    transition: 0.2s ease-in-out;
}

.nav_link:hover:before{
    transform: scale(1.4);
}

.highlight:hover:before {
    transform: scale(1.1);
}
div[class^="lstbut_icon"] {
    color: rgb(255, 242, 242);
    background: rgb(0, 0, 0);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin: 5px 5px;
    border: 2px solid white;
    display: grid;
    place-items: center;
    cursor: pointer;
    
}

.lstbut_parent_icon {
    position: fixed;
    right: 10px;
    bottom: -50px;
    z-index: 10;
}
.lstbut_icon_main {
    animation: appear .5s ease-in-out 0s 1 alternate;
}
.lstbut_icon_home {
   
}
.lstbut_icon_skills {
    position: relative;
    right: 52px;
    top: 87px;
}
.lstbut_icon_projects {
    position: relative;
    right: 55px;
    bottom: 85px;
}
.lstbut_icon_socials {
    
}

.hide {
    display: none;
}

div[class $= "invisible"] {
    visibility: hidden;
}


@keyframes appear {
    20% {
        box-shadow: 0px 0px 69px 53px rgba(219,123,123,0.96);
    }
    40%{
        box-shadow: 0px 0px 115px 53px rgba(242,160,24,0.96);
    }
    60% {
        box-shadow: 0px 0px 78px 40px rgba(69,194,247,0.96);
    }
    80% {
        box-shadow: 0px 0px 60px 16px rgba(111,242,65,0.96);
    }
}



.dark_toggle {
    position: fixed;
    right: 10px;
}

#toggle {
    display: none;
}

.toggle_label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    border: 2px solid white;
    background: linear-gradient(to right,rgb(210, 202, 202)5%, rgb(151, 157, 161)40%, rgb(111, 113, 114)80%);
    border-radius: 20px;
    cursor: pointer;
    transition: all .3s linear;
}

.toggle_label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: linear-gradient(to top right,rgb(44, 120, 182)10%, rgb(108, 168, 218)20%, rgb(216, 240, 245)60%);
    transform: translateX(20px);
    border-radius: 10px;
    transition: all .3s ease-out;
}

input[type="checkbox"]:checked + .toggle_label:after {
    background: linear-gradient(rgb(255, 247, 0)10%, rgb(255, 115, 0)100%);
    transform: rotate(225deg);
}

input[type="checkbox"]:checked + .toggle_label{
    background: linear-gradient(to right,rgb(247, 170, 5)10%,  rgb(247, 181, 39)30%, rgb(250, 217, 164)100%);
}





/* fancy_setup portion */
div[id^="home"] {
    position: absolute;
    top: 0px;
    left: 0px;
    background: radial-gradient(circle at 20vw 50vh, rgb(245, 225, 69)10%, rgb(113, 187, 73)20%, rgb(36, 69, 104)60%, rgb(25, 3, 112)80%);
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 2fr 1fr;
    
}

#home-light {
    background: radial-gradient(circle at 20vw 50vh, rgb(255, 115, 0)10%, rgb(239, 126, 78)20%, rgb(226, 219, 252)60%); 
}

[class ^= "oval_canvas"] {
    margin-left: 10px;
    background: url("./images/night_sky.jpg");
    width: 90%;
    height: 90vh;
    max-width: 70vh;
    border-radius: 50%;
    border: 1rem solid rgb(207, 218, 213);
    background-size: cover;
    background-position: 0% center;
}

.oval_canvas-light {
    background: url("./images/sunny_sky.jpg");
    background-size: cover;
    background-position: right center;
}

.win_frame_1 {
    position: relative;
    background-color: rgb(207, 218, 213);
    width: 100%;
    height: .5rem;
    top: -49%;
    /* z-index: 2; */
}
.win_frame_2 {
    position: relative;
    background-color: rgb(207, 218, 213);
    width: .5rem;
    height: 100%;
    left: 50%;
    /* z-index: 1; */
}

.lamp_body {
    position: absolute;
    top: 0px;
    left: 8vw;
    z-index: 2;
    width: 20vh;
    height: 40vh;
}
.bulb {
    position: absolute;
    top: 40vh;
    left: 8vw;
    margin-left: 6vh;
    z-index: 2;
    width: 8vh;
    /* height: 45vh; */
}
[class ="lamp_light"] {
    background-color: transparent;
    position: absolute;
    top: 40vh;
    left: 8vw;
    width: 8vh;
    border-right: 6vh solid transparent;
    border-left: 6vh solid transparent;
    border-bottom: 40vh solid rgba(255, 238, 3, 0.589);
    border-radius: 0px 0px 200px 200px;
    z-index: 3;
    animation: flicker 1.5s ease-out 0s 1 forwards;
}
.lamp_light-light {
   display: none;
}

@keyframes flicker {
    0% {
        border-bottom: 0px solid rgba(255, 238, 3, 0);
    }
    50% {
        border-bottom: 40vh solid rgba(255, 238, 3, 0.589);
    }
    80% {
        border-bottom: 40vh solid rgba(255, 238, 3, 0);
    }
    100% {
        border-bottom: 40vh solid rgba(255, 238, 3, 0.589);
    }
}

.desk {
    position: absolute;
    top: 74vh;
    margin-left: 5px;
    z-index: 1;
}

.desk_frame {
    background: linear-gradient(to right, rgb(87, 55, 14)30%,rgb(199, 140, 63)90%);
    background-color: brown;
    width: 70vw;
    height: 100px;
    transform: skewX(-45deg);
}

.edge_1 {
    background-color: #62451e;
    width: 70vw;
    height: 30px;
    border: 2px solid black;
    margin-left: -57px;
    
}
.edge_2 {
    position: relative;
    left: 70vw;
    top: -85px;
    background-color: #3e2b13;
    width: 100px;
    height: 30px;
    border: 2px solid black;
    margin-left: -55px;
    transform: skewY(-45deg);
    border-radius: 0px 8% 0px 0px;
}

.alarm_clock {
    position: relative;
    left: 40vw;
    top: -250px;
    z-index: 3;
}

.alarm_clock_base {
    width: 100px;
}

.clock_padding {
    position: relative;
    top: -95px;
    left: 10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: white;
}

.central_dot {
    position: relative;
    top: 42%;
    left: 42%;
    background-color: red; 
    width: 14px;
    height: 14px;
    border-radius: 100%;
    z-index: 6;
}

[class ^= "clock_hour_hand"] {
    position: relative;
    top: 27px;
    left: 37px;
    background-color: rgb(0, 0, 0); 
    width: 8px;
    height: 30px;
    border-radius: 10px;
    z-index: 4;
    transform: rotate(300deg);
    transform-origin: center top;
    transition: transform 1.7s linear;
}
[class ^= "clock_min_hand"] {
    position: relative;
    top: -3px;
    left: 38px;
    background-color: rgb(0, 0, 0); 
    width: 5px;
    height: 35px;
    border-radius: 10px;
    z-index: 5;
    transform: rotate(70deg);
    transform-origin: center top;
    transition: transform 1.3s linear;
}
[class ^= "clock_sec_hand"] {
    position: relative;
    top: -38px;
    left: 39px;
    background-color: rgb(247, 68, 68); 
    width:3px;
    height: 35px;
    border-radius: 10px;
    z-index: 5;
    transform: rotate(190deg);
    transform-origin: center top;
    transition: transform 1s linear;
}

.clock_hour_hand-light {
    transform: rotate(435deg);
}
.clock_min_hand-light {
    transform: rotate(170deg);
}
.clock_sec_hand-light {
    transform: rotate(320deg);
}

.plant {
    position: relative;
    top: -520px;
    left: 40vw;
    margin-left: 120px;
    z-index: 7;
    
}

.plant_pic {
    height: 200px;
    z-index: 7;
}

/* intro part */
[class^="intro"] {
    z-index: 0;
    height: 100%;
    background: rgb(46, 44, 45);
    font-size: 3rem;
    text-align: center;
    color: white;
}
.intro-light {
    background: rgb(205, 250, 187);
    color: #000000;
}

.letters {
    font-family: lato;
    display: inline-block;
}
.letters:hover {
    color: #08fb55;
    animation: springs 0.5s ease-out 0s 1 normal forwards;
    
}

.description {
    position: relative;
    top: 10%;
}

@keyframes springs {
    0%{
        transform: scale3d(1,1,1);
    }
    40%{
        transform: scale3d(1.4,.55,1);
    }
    60%{
        transform: scale3d(1.75,.1.25,1);
    }
    70%{
        transform: scale3d(1.25,.85,1);
    }
    80%{
        transform: scale3d(.9,.1.05,1);
    }
    100%{
        transform: scale3d(1,1,1);
    }
    
}

.word {
    font-size: 4rem;
    color: white;
    font-family: 'Gochi Hand', cursive;
    
}

[class ^="blob_2"] {
    position: absolute;
    background-image: url(./images/samyak.jpg);
    background-size: cover;
    background-position: 0px 50%;
    z-index: -1;
    width: 30%;
    height: 50%;
    top: 40%;
    margin-left: 30px;
    animation: blob_2 10s ease-in-out infinite normal forwards;
    border: 5px solid white;
}

.blob_2-light {
    border: 5px solid rgb(0, 0, 0);
}

@keyframes blob_2 {
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
    25% { 
        border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
    }
    50% {
        border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    }
    75% {
        border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
    }
    100% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    }
}


/* SKILLS PORTION */
[id ^= "skills"] {
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: rgb(17, 16, 16);
    width: 100vw;
    height: 100vh;
    margin-left: 0px;
    margin-bottom: 0px;
    color: white;
}

[class ^= "typing"] {
    margin-left: 15px;
    font-family: 'Anonymous Pro', monospace;
    white-space: nowrap;
    overflow: hidden;
    font-size: 4rem;
    border-right: 2px solid rgb(255, 255, 255);
    animation: typewriter 4s steps(28) infinite normal, blinkcursor 0.5s infinite normal;
}

#skills-light {
    background-color: rgb(174, 172, 242);
    color: rgb(0, 0, 0);
}

.typing-light {
    border-right: 2px solid rgb(0, 0, 0);
    animation: typewriter 4s steps(28) infinite normal, blinkcursorblack 0.5s infinite normal;
}

@keyframes typewriter {
    from {
        width: 0px;
    } to {
        width: 440px;
    }
}

@keyframes blinkcursor {
    from {
        border-right-color: rgb(255, 255, 255);
    } to {
        border-right-color: transparent;
    }
}
@keyframes blinkcursorblack {
    from {
        border-right-color: rgb(0, 0, 0);
    } to {
        border-right-color: transparent;
    }
}

.wrapper > div {
    margin: 1rem 1rem;
    border-radius: 15px;
    color: transparent;
    font-size: larger;
    z-index: 7;
    cursor: pointer;
}
.wrapper > div:hover {
    transform: translate(10px, 13px);
}

.wrapper {
    gap: 2rem;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-right: 1rem;
    text-align: center;
    /* cursor: pointer; */
}

.starbar {
    text-align: center;
    margin: 10vh 0px;
}

[class^="star"] {
    color: yellow;
}

.hide_star {
    color: white;
}


/* projects section */
[id ^= "myproj"] {
    color: white;
    text-align: center;
    font-family: 'Anonymous Pro', monospace;
    position: absolute;
    top: 200vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #1e1f1e;
}
#myproj-light {
    background-color: #edffed;
    color: black;
}


.proj_cards_1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 5vh 25px 0px 15px;
}

.proj_cards_2 {
    /* max-width: 50vw; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 5vh 25px 0px 15px;
}

[class^="proj_frame"] {
    border-radius: 10px;
    border: 2px solid rgb(255, 255, 255);
    background-color: rgb(32, 32, 31);
    height: 35vh;
    cursor: pointer;
}
.proj_frame-light {
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(209, 235, 242);
}
.proj_img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.proj_letters {
    display: inline-block;
}
.proj_letters-light {
    color: #000000;
    display: inline-block;
}
.proj_letters:hover, .proj_letters-light:hover{
    color: #f03e08;
    animation: springs 0.5s ease-out 0s 1 normal forwards;
    
}


/* modal section */
.modal_overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.438);
    z-index: 10;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.modal {
    width: 80vw;
    height: 90vh;
    background-color: white;
}

.mac_tab {
    background-color: #dee1e6;
    /* background-color: #2a3033; */
}

.details {
    font-family: lato;
    /* background-color: rgb(255, 0, 0); */
    height: 70%;
    width: 70%;
    position: relative;
    top: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
}

.project_title {
    font-family: 'Gochi Hand', cursive;
    font-size: 2rem;
    margin-bottom: 2rem;
}

.modal_links {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.languages_used {
    margin-bottom: 1rem;
}

.website_source, .website_link {
    text-decoration: none;
    background-color: #2a3033;
    color: white;
    margin: 10px 1rem;
    padding: 10px;
    border-radius: 30px;
    position: relative;
}

.website_source:before, .website_link:before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 40px;
    width: 100%;
    transform: scale(0);
    background-color: rgba(222, 245, 17, 0.507);
    border-radius: 50px;
    transition: 0.2s ease-in-out;
}

.website_source:hover:before, .website_link:hover:before {
    transform: scale(1);
}

div[class$="scroll"] {
    position: absolute;
    background: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 4px solid #dee1e6;
    display: grid;
    place-items: center;
    color: #dee1e6;
    cursor: pointer;
}

.left_scroll{
    right: 90vw;
    transform: translateX(39px);
}
.right_scroll {
    left: 90vw;
    transform: translateX(-39px);
}

.youtube {
    width: 48vw;
    height: 27vw;
    position: relative;
    left: 50%;
    transform: translate(-50%, 5px);
}


/* contact page */
[id^="contact"] {
    position: absolute;
    top: 300vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f5f3fd;
    display: grid;
    grid-template-columns: 1fr 2fr;
    overflow: hidden;
}

.contact_frame {
    background-color: #141414;
    /* width: 40vw; */
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact_frame label {
    color: white;
    font-family: 'Gochi Hand', cursive;
    font-size: 2rem;
}
.contact_frame input, .contact_frame textarea {
    width: 30vw;
    margin: 10px 0;
    color: white;
    font-size: 1.5rem;
    height: 5vh;
    border-radius: 10px;
    border: 2px solid rgb(250, 153, 62);
    background-color: rgba(243, 237, 237, 0.13);
}

.contact_frame input:focus, .contact_frame textarea:focus {
    background-color: rgba(220, 233, 232, 0.37);
}

.contact_frame textarea {
    height: 30vh;
    /* background: #1e1f1e; */
}

.contact_us_1 {
    width: 50vw;
    margin-right: 20px;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.me {
    position: relative;
    top: 40%;
    left: 10vw;
    z-index: 1;
}
.m {
    background-color: #000000;
    padding: 20px 20px;
    margin-right: 20px;
    max-width: 150px;
    width: 25vw;
}
.e {
    max-width: 150px;
    width: 25vw;
}

.triangle {
    width: 0; 
    height: 0; 
    border-left: 15vw solid transparent;
    border-right: 15vw solid transparent;
    border-bottom: 25vw solid rgb(247, 122, 113);
    position: absolute;
    bottom: 30%;
    right: 0px;
}

.semi_circle {
    background: yellow;
    width: 20vw;
    height: 20vw;
    border-radius: 100%;
    position: absolute;
    top: 10%;
    left: 24vw;
}

.contact_frame input[type="submit"] {
    font-family: 'Anonymous Pro', monospace;
    background-color: orange;
    color: white;
    width: 31vw;
    height: 7vh;
    cursor: pointer;
    
}

.contact_frame input[type="submit"]:hover {
    transform: translate(0, -2px);
    box-shadow: 0px 6px 5px 0px rgba(227,171,27,0.75);
}

.socials {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 5px;
    position: relative;
    bottom: 0px;
}

.socials img {
    cursor: pointer;
}
.socials img:hover {
    transform: translate(0, -5px);
    transition: 0.2s ease-out;
    box-shadow: 0px 0px 35px 0px rgba(255,255,255,0.74);
    border-radius: 100%;
}

[id ^= "contact"] {
    overflow: hidden;
}
[id ^= "skills"] {
    overflow: hidden;
}
[id ^= "contact"] {
    overflow: hidden;
}
[id ^= "myproj"] {
    overflow: hidden;
}
[id ^= "home"] {
    overflow: hidden;
}

@media only screen and (max-width:700px) {

    body{
        font-size: 0.6rem;
    }
    [class^="intro"] {
        font-size: 2rem;
        position: absolute;
        right: 0%;
        width: 50vw;
        background-color: transparent;
        overflow-x: hidden;
    }
    
    .description {
        position: relative;
        top: 10%;
    }
    .plant {
        position: relative;
        left: 10vw;
        z-index: 7;
        
    } 
    
    .alarm_clock {
        position: relative;
        left: 30vw;
        top: -250px;
        z-index: 3;
    }
 
    [class ^="blob_2"] {
        /* position: absolute;
        background-image: url(./images/samyak.jpg);
        background-size: cover;
        background-position: 0px 50%; */
        z-index: -1;
        width: 200px;
        height: 200px;
        top: 40%;
        margin-left: 30px;
        animation: blob_2 10s ease-in-out infinite normal forwards;
        border: 5px solid white;
    }
    
    .blob_2-light {
        border: 5px solid rgb(0, 0, 0);
    }

    #myproj {
        overflow: hidden;
    }
    .wrapper {
        font-size: 0.7rem;
        gap: .5rem;
        display: grid;
        grid-template-columns: auto auto;
        /* cursor: pointer; */
    }

    [class ^= "typing"] {
        margin-left: 15px;
        font-family: 'Anonymous Pro', monospace;
        white-space: nowrap;
        overflow: hidden;
        font-size: 2rem;
        border-right: 2px solid rgb(255, 255, 255);
        animation: typewriter 4s steps(28) infinite normal, blinkcursor 0.5s infinite normal;
    }
           
    @keyframes typewriter {
        from {
            width: 0px;
        } to {
            width: 230px;
        }
    }
    
    .proj_cards_1 {
        grid-template-columns: 1fr 1fr;
    }
    #proj2 {
        position: relative;
        left: 50%;
    }


    .m {
        margin-bottom: 20px;
    }

    .triangle {
        top: 30%;
    }
    .semi_circle {
        position: absolute;
        top: 10%;
        left: 30vw;
    }

    [class^="proj_frame"] {
        height: 25vh;
    }

    .toggle_label {
        width: 24px;
        height: 12px;
    }
    
    .toggle_label:after {
        top: 1px;
        left: 1px;
        width: 10px;
        height: 10px;
        transform: translateX(11px);
    }
    .nav_bar {
        position: fixed;
        display: grid;
        place-items: center start;

    }
    
    .modal {
        width: 80vw;
        height: 80vh;
        overflow: scroll;
    }
    
    .details {
        font-size: 1rem;
    }
   
    .modal_links {
        flex-direction: column;
        row-gap: 2rem;
    }
    
    .youtube {
        width: 72vw;
        height: 40.5vw;
    }
    

    [id ^= "contact"] {
        overflow: hidden;
    }
    [id ^= "skills"] {
        overflow: hidden;
    }
    [id ^= "contact"] {
        overflow: hidden;
    }
    [id ^= "myproj"] {
        overflow: hidden;
    }
    [id ^= "home"] {
        overflow: hidden;
    }
}